﻿export class RefreshToken {
    // ID
    public id: string = null;
    // SUBJECT
    public subject: string = null;
    // CLIENTID
    public clientId: string = null;
    // ISSUEDUTC
    public issuedUtc: Date = new Date();
    // EXPIRESUTC
    public expiresUtc: Date = new Date();
    // PROTECTEDTICKET
    public protectedTicket: string = null;
}