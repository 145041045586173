﻿export class Base {
    // ID
    public id: number = 0;
    // CREATEDDATE
    public createdDate: Date = new Date();
    // MODIFIEDDATE
    public modifiedDate: Date = null;
    // ISDELETED
    public isDeleted: boolean = false;
    // ISACTIVE
    public isActive: boolean = false;
    // CREATEDBYID
    public createdById: string = null;
    // CREATEDBY
    public createdBy: string = null;
    // MODIFIEDBYID
    public modifiedById: string = null;
    // MODIFIEDBY
    public modifiedBy: string = null;
}