export enum RatingTypeEnum {
    best = 0,
    cheap = 1,
    professional = 2,
    reliable = 3,
    safe = 4,
    top = 5,
    trusted = 6,
}

export namespace RatingTypeEnum {
    export function keys(): Array<string> {
        var keys = Object.keys(RatingTypeEnum);
        return keys.slice(keys.length / 2, keys.length - 1);
    }

    // export function values(): Array<string> {
    //     var keys = Object.values(RatingTypeEnum);
    //     return keys.slice(keys.length / 2, keys.length - 1);
    // }
}