import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { ApiService } from "../shared/services/index";
import { City, Service, CompanyService, ServiceType, Enquiry } from "../shared/modals/index";
import * as _ from "lodash";
import { SweetAlertService } from 'ngx-sweetalert2';

@Component({
  selector: "enquiry",
  styleUrls: ["./enquiry.component.css"],
  templateUrl: "./enquiry.component.html"
})
export class EnquiryComponent implements OnInit {
  cities: City[] = [];
  usersCities: City[] = [];
  serviceTypes: ServiceType[] = [];
  enquiries: Enquiry[] = [];
  companyServices: CompanyService[] = [];
  filters: any={
      pageNumber: 1,
      pageSize: 100,
      serviceType: [],
      serviceId: "",
      from: [],
      to: "",
      date: ""
    };
  service: number;
  usersCompanyId: number;
  isloading: boolean = false;

  constructor(private route: ActivatedRoute, private router: Router,private ApiService: ApiService) {
    this.service = +this.route.snapshot.params.serviceId;
  }

  ngOnInit() {
    if(localStorage.getItem('userInfo')){
      this.usersCompanyId = JSON.parse(localStorage.getItem('userInfo')).companyId;
    }
    this.clearFilters();
    this.ApiService.get("Cities", "").subscribe(cities => this.cities = cities);
    this.ApiService.get("ServiceTypes", "").subscribe(res => this.serviceTypes = res);
    this.ApiService.get("Companies/" + this.usersCompanyId, "").subscribe(userCompany => this.usersCities = (userCompany.companyAddresses || []).map(companyAddress => companyAddress.cityId));
  }

  getShiftingType(filters){
    let shiftingTypes = JSON.parse((filters || {})) || [];
    return shiftingTypes.map(item => {
      return item.value.value;
    }).join(', ');
  }

  getEnquiry() {
    this.isloading = true;
    let params = JSON.parse(JSON.stringify(this.filters));
    params.serviceType = (params.serviceType || []).join(',');
    params.from = (params.from || []).join(',');

    this.ApiService.get("Enquiries", params).subscribe(res => {
      this.enquiries = res.result || [];
      this.isloading = false;
    });
  }

  clearFilters() {
    this.filters = {
      pageNumber: 1,
      pageSize: 100,
      serviceType: [],
      serviceId: "",
      from: [],
      to: "",
      date: ""
    };
    this.getEnquiry();
  }

  buyLead(enq) {
    if(enq.movingFromId && ((this.usersCities || []).indexOf(enq.movingFromId) >= 0)){
      var newLead = {
        enquiryId: enq.id,
        responseDate: new Date(),
        status: 1,
        creditCost: enq.minCredit,
        isActive: true
      };
      this.ApiService.post("Leads", newLead).subscribe(res => {
        //  this._swal2.success({ title: 'Your request successfully placed.' }).then(()=>{
        //    this.router.navigate(['/leads']);
        //  })
      },
      error => {
        console.log(error);
        // this._swal2.success({ title: JSON.parse(error._body || {}).message, type: 'warning', }).then(()=>{
        // })
      });
    } else {
      // this._swal2.error({ title: 'You\'r not serving in this city.' });
    }

  }
}
