import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';
import { Response, RequestOptions, Headers } from '@angular/http';
import 'rxjs/add/operator/map';
import { config } from '../../app.config'
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

@Injectable()
export class ApiService {
    token: any;
    public httpConfig:any;

    constructor(private http: HttpClient) {
        // this.httpConfig = conf;
        this.setHeaders();
    }

    setHeaders(){
        if (localStorage.token) {
            this.token = JSON.parse(localStorage.token);
            this.httpConfig.headers= new HttpHeaders({ 'Authorization': this.token.token_type + ' ' + this.token.access_token });
        }
    }

    private makeRequest() {
        this.setHeaders();
        return this.http.request(this.httpConfig.url, this.httpConfig).map((res: any) => !res._body ? res : res.json());
    }

    delete(url: string, params: any) {
        this.httpConfig.url = config.API_URL + url;
        this.httpConfig.method = 'Delete';
        this.httpConfig.params = params || '';
        return this.makeRequest();
    }

    // get(url: string, params: any) {
    //     this.httpConfig.url = config.API_URL + url;
    //     this.httpConfig.method = 'Get';
    //     this.httpConfig.params = params || '';
    //     return this.makeRequest();
    // }

    get(path: string, params?: any): Observable<any> {
        if(params){
          let httpParams = new HttpParams();
          Object.keys(params).forEach(key => {
            httpParams.set(key, params[key]);
          });
        }
        return this.http.get(`${config.API_URL}${path}`, { params })
          .pipe(map((value, index) => value));
      }

    put(url: string, data: any) {
        this.httpConfig.url = config.API_URL + url;
        this.httpConfig.method = 'Put';
        this.httpConfig.body = data || '';
        return this.makeRequest();
    }

    post(url: string, data: any) {
        this.httpConfig.url = config.API_URL + url;
        this.httpConfig.method = 'Post';
        this.httpConfig.body = data || '';
        return this.makeRequest();
    }

    login(user: any) {
        return this.http.post(config.URL + 'Token', `grant_type=password&username=${user.username}&password=${user.password}`,
            { headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }) })
            .map((res: Response) => res.json());
    }

    isLoggedIn() {
        return !!localStorage.getItem('token');
    }

}

