import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { City } from '../modals/'

@Injectable()
export class CityService {
  constructor(private ApiService: ApiService) { }

  getAll() {
    return this.ApiService.get('Cities', '');
  }
}
