﻿import { Base } from './Base';

export class UserCredit extends Base {
    // EXPIRYDATE
    public expiryDate: Date = new Date();
    // PACKAGETYPE
    public packageType: string = null;
    // PAYMENTMETHOD
    public paymentMethod: string = null;
    // PAYMENTSTATUS
    public paymentStatus: string = null;
    // TRANSACTIONID
    public transactionId: string = null;
    // TRANSACTIONTYPE
    public transactionType: string = null;
    // PRICE
    public price: number = 0;
    // BONUS
    public bonus: number = 0;
    // NETVALUE
    public netValue: number = 0;
    // NETCREDIT
    public netCredit: number = 0;
    // USERID
    public userId: string = null;
    // USER
    public user: string = null;
}