﻿import { Base } from './Base';
import { RatingTypeEnum } from '../enums/index'
import { Company } from './Company';
import { Service } from './Service';
import { CompanyServiceArea } from './CompanyServiceArea';
 
export class CompanyService extends Base {
    // COMPANYID
    public companyId: number = 0;
    // COMPANY
    public company: Company = new Company();
    // SERVICEID
    public serviceId: number = 0;
    // SERVICE
    public service: Service = null;
    // SERVICEAREAS
    public serviceAreas: CompanyServiceArea[] = [];

    public rating: number = 0;

    public reviewCount: number = 0;

    public ratingTypes: RatingTypeEnum[] = [];
}
