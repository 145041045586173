﻿import { Base } from './Base';
import { CompanyServiceArea } from './CompanyServiceArea';
import { Company } from './Company';

export class CompanyServiceReview extends Base {
    // USERID
    public userId: string = null;
    // USER
    public user: string = null;
    // COMPANYSERVICEAREAID
    public companyServiceAreaId: number = null;
    // COMPANYSERVICEID
    public companyServiceId: number = null;
    // COMPANYSERVICEAREA
    public companyServiceArea: CompanyServiceArea = null;
    // COMPANYID
    public companyId: number = null;
    // COMPANY
    public company: Company = null;
    // RATING
    public rating: number = 0;
    // RATINGTYPE
    public ratingType: number = 0;
    // REVIEW
    public review: string = null;
    // SUMMARY
    public summary: string = null;
    // NAME
    public name: string = null;
    // MOBILENO
    public mobileNo: string = null;
    // EMAIL
    public email: string = null;
    // MOVINGDATE
    public movingDate: Date = new Date();
}