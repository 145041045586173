import { Component, OnInit } from "@angular/core";
import { Blog } from "../shared/modals/";
import { BlogService } from "../shared/services/";
import { config } from "../app.config";

@Component({
  selector: "blog",
  styleUrls: ["./blog.component.css"],
  templateUrl: "./blog.component.html"
})
export class BlogComponent implements OnInit {
  blogs: Blog[] = [];
  totalRecords: number = 0;
  options: any = {
    pageNumber: 1,
    pageSize:6
  };
  URL: string = config.URL;

  constructor(public blogService: BlogService) {}

  ngOnInit() {
    this.blogService.getAll(this.options).subscribe(blogs => {
      this.totalRecords = blogs.count || 0;
      if (blogs && blogs.result) {
        this.blogs = this.blogs.concat(blogs.result);
      }
    });
  }

  pageChange() {
    if (this.totalRecords > this.blogs.length) {
      this.options.pageNumber++;
      this.ngOnInit();
    }
  }
}
