import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Blog } from '../modals/'

@Injectable()
export class BlogService {
  constructor(private ApiService: ApiService) { }

  get(id: number) {
    return this.ApiService.get(`Blogs/${id}`, '');
  }

  getAll(options) {
    return this.ApiService.get('Blogs', options);
  }

  put(blog: Blog) {
    return this.ApiService.put('Blogs', blog);
  }

  post(blog: Blog) {
    return this.ApiService.post('Blogs', blog);
  }
}
