import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { City, Service, Company, CompanyService, ServiceType } from "../shared/modals";
import { RatingTypeEnum } from "../shared/enums";
import { ApiService } from "../shared/services/";
import { config } from "../app.config";
import { MetaService } from "ng2-meta";
@Component({
  selector: "companies",
  styleUrls: ["./companies.component.css"],
  templateUrl: "./companies.component.html"
})
export class CompaniesComponent implements OnInit {
  cities: City[] = [];
  services: Service[] = [];
  companyServices: CompanyService[] = [];
  serviceTypes: ServiceType[] = []

  serviceDescription: string = '';
  totalCount: number;
  ratings: any = RatingTypeEnum;
  companyServicesFilter: any = {};
  isloadingCompanyServices: boolean = false;
  URL: string = config.URL;

  constructor(private route: ActivatedRoute, private ApiService: ApiService) {

    this.companyServicesFilter = {
      pageNumber: 1,
      pageSize: 10,
      cityId: '',
      companyId: '',
      serviceId: '',
      serviceTitle: '',
      ratingType: ''
    };

    this.companyServicesFilter.serviceTitle = (this.route.snapshot.params.serviceId||'').replace(" ", "-");
  }

  ngOnInit() {
    setTimeout(() => {
      // this.metaService.setTitle('Professional Packers and Movers in ' + this.companyServicesFilter.serviceTitle);
    }); //example

    this.ApiService.get("Cities", "").subscribe(
      cities => (this.cities = cities)
    );
    this.ApiService.get("Services", "").subscribe(
      services => {
        this.services = services;
        this.getServiceDescription();
      }
    );
    this.ApiService.get("ServiceTypes", "").subscribe(
      ServiceTypes => (this.serviceTypes = ServiceTypes)
    );
    this.getCompanyServices();
  }

  getServiceDescription() {
    this.serviceDescription = this.serviceDescription||(this.companyServicesFilter.serviceTitle ? (((this.services || [])
    .find(service => (service.title||'').toLowerCase() == (this.companyServicesFilter.serviceTitle||'').replace('-',' ').toLowerCase())||{})['description'] || '') : '');
  }

  getCompanyServices() {
    this.isloadingCompanyServices = true;
    this.ApiService.get("CompanyServices", this.companyServicesFilter).subscribe(CompanyServices => {
      this.companyServices = (CompanyServices.result || []);
      this.serviceDescription = this.serviceDescription||(((this.companyServices[0]||{})['service']||{}).description||'');
      this.totalCount = CompanyServices.count;
      this.companyServices.forEach(companyService => {
        let reviews = (companyService.company.companyServiceReviews || []).filter(a=>a.isActive);
        //.filter(csReview => csReview.companyServiceAreaId == companyService.id);
        companyService.reviewCount = reviews.length;
        let count: number = this.average(reviews, 'rating') || 0;
        companyService.rating = count*100/5;//count >= 10 ? 10 : (count * 10);
        console.log(count);
        companyService.ratingTypes = Object.keys(this.groupBy(companyService.company.companyServiceReviews, 'ratingType')).map(ratingType => RatingTypeEnum[ratingType]);
      });
      this.getServiceDescription();
      this.isloadingCompanyServices = false;
    });
  }

  groupBy(array, prop) {
    return (array || []).reduce(function (groups, item) {
      let val = item[prop];
      groups[val] = groups[val] || [];
      groups[val].push(item);
      return groups;
    }, {});
  }

  average(array, prop) {
    if (prop) {
      return ((array || []).reduce(function (a, b) { return a + b[prop]; }, 0) / (array || []).length);
    } else {
      return ((array || []).reduce(function (a, b) { return a + b; }) / (array || []).length);
    }
  }


}
