import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { City, Service, Company, CompanyService, ServiceType, Enquiry, CategoryFilter } from "../shared/modals/index";
import { RatingTypeEnum } from "../shared/enums";
import { ApiService } from "../shared/services/";
import { config } from "../app.config";
import { SweetAlertService } from "ngx-sweetalert2";
import { MetaService } from "ng2-meta";
@Component({
  selector: "city-detail",
  styleUrls: ["./city-detail.component.css"],
  templateUrl: "./city-detail.component.html"
})
export class CityDetailComponent implements OnInit {
  services: Service[] = [];
  companyServices: CompanyService[] = [];
  serviceTypes: ServiceType[] = [];
  city: any = {};

  cityId: number|string;
  totalCount: number;
  ratings: any = RatingTypeEnum;
  companyServicesFilter: any = {};
  isloadingCompanyServices: boolean = false;
  URL: string = config.URL;
  OPTIMIZATION: any = config.OPTIMIZATION;

  cities: City[] = [];
  fromCities: City[] = [];
  toCities: City[] = [];
  testimonials: any[] = [];
  enquiry: Enquiry;
  categoryFilters: CategoryFilter[] = [];
  tempfilterValues: any = [];
  tempFiletrCheckbox: any = {};

  isOtpSent: boolean = false;
  isValidOtp: boolean = true;
  disableShiftType: boolean = true;
  fromCityTypeaheadNoResults: boolean = false;
  toCityTypeaheadNoResults: boolean = false;

  minDate = new Date();

  constructor(private route: ActivatedRoute, private ApiService: ApiService) {
    this.cityId = this.route.snapshot.params.cityId;

    this.companyServicesFilter = {
      pageNumber: 1,
      pageSize: 10,
      cityId: '',
      cityName: this.cityId,
      companyId: '',
      serviceId: '',
      ratingType: ''
    }

    this.minDate.setDate(this.minDate.getDate() + 1);
    this.enquiry = new Enquiry();
    this.enquiry.shiftingDate = this.minDate;

    setTimeout(() => {
      // this.metaService.setTitle((this.OPTIMIZATION[this.cityId] || {}).TITLE || 'Professional Packers and Movers in ' + (this.cityId||'').toString().replace('-',' '));
      // this.metaService.setTag('description', (this.OPTIMIZATION[this.cityId] || {}).DESCRIPTION || 'Hire best packers and movers from Oakfeat.com for home shifting in ' + (this.cityId||'').toString().replace('-',' ') + '. Our aims to provide safe and reliable relocation services at expectable price in minimum time.');
      // this.metaService.setTag('keywords','packers and movers in ' + (this.cityId||'').toString().replace('-',' '));
    }); //example
  }

  onChangeFromCityTypeahead(e: boolean): void {
    this.fromCityTypeaheadNoResults = e;
  }

  onChangeToCityTypeahead(e: boolean): void {
    this.toCityTypeaheadNoResults = e;
  }

  ngOnInit() {
    this.ApiService.get("city/"+this.cityId, "").subscribe(city => this.city = city);

    this.ApiService.get('Cities', '').subscribe(cities => {
      this.cities = cities;
      this.fromCities = cities;
      this.toCities = cities;
    });
    this.ApiService.get("Services", "").subscribe(services => this.services = services);
    this.ApiService.get("ServiceTypes", "").subscribe(ServiceTypes => this.serviceTypes = ServiceTypes);

    this.tempfilterValues.forEach(element => {
      this.tempFiletrCheckbox[element.filter.id + '' + element.value.id] = true;
    });

    this.ApiService.get('CategoryFilters', '').subscribe(CategoryFilters => {
      this.ApiService.get('CategoryFilterValues', '').subscribe(CategoryFilterValues => {
        this.categoryFilters = CategoryFilters;
        CategoryFilterValues.forEach(CategoryFilterValue => {
          let categoryFilter = this.categoryFilters.find(categoryFilter => categoryFilter.id == CategoryFilterValue.categoryFilterId);
          categoryFilter.values = categoryFilter.values || [];
          categoryFilter.values.push(CategoryFilterValue);
        });;
      });
    });

    this.getCompanyServices();
  }

  getCompanyServices() {
    this.isloadingCompanyServices = true;
    this.ApiService.get("CompanyServices", this.companyServicesFilter).subscribe(CompanyServices => {
      this.companyServices = (CompanyServices.result || []);
      this.totalCount = CompanyServices.count;
      this.companyServices.forEach(companyService => {
        let reviews = (companyService.company.companyServiceReviews || []).filter(a=>a.isActive);
        //.filter(csReview => csReview.companyServiceAreaId == companyService.id);
        companyService.reviewCount = reviews.length;
        let count: number = Math.round(this.average(reviews, 'rating')) || 0;
        companyService.rating = count*100/5;//count >= 10 ? 10 : (count * 10);
        companyService.ratingTypes = Object.keys(this.groupBy(companyService.company.companyServiceReviews, 'ratingType')).map(ratingType => RatingTypeEnum[ratingType]);
      });
      this.isloadingCompanyServices = false;
    });
  }

  setMovingFromId(city) {
    this.enquiry.movingFromId = city.item.id;
    if(this.enquiry.serviceTypeId == 1){
      this.toCities = this.cities.filter(item => city.item.id != item.id);
    }
  }

  setMovingToId(city) {
    this.enquiry.movingToId = city.item.id;
    if(this.enquiry.serviceTypeId == 1){
      this.fromCities = this.cities.filter(item => city.item.id != item.id);
    }
  }

  onChangeFilterValue(isChecked, value, filter) {
    this.disableShiftType = true;
    if (isChecked) {
      this.tempfilterValues.push({ value: value, filter: filter });
    } else {
      this.tempfilterValues.splice(this.tempfilterValues.indexOf({ value: value, filter: filter }), 1);
    }
    for (let tmp in this.tempFiletrCheckbox) {
      if (this.tempFiletrCheckbox[tmp]) {
        this.disableShiftType = false;
        break;
      }
    }
    this.enquiry.filterValues = JSON.stringify(this.tempfilterValues);
  }

  postEnquiry() {
    if(this.enquiry.serviceTypeId == 3){
      this.enquiry.movingToId = this.enquiry.movingFromId;
    }
    this.enquiry.isActive = true;
    this.ApiService.post('Enquiries', this.enquiry).subscribe(Enquiry => {
      document.getElementById("closeOtpVerficationModal").click();
      // this._swal2.success({ title: 'Your enquiry is submitted successfully.' }).then(()=>{
      // });
    });
  }

  sendOtp(mobileNo, email, from, reSend) {
    this.ApiService.get(`generateOtp?mobileNo=${mobileNo}&$email=${email}&from=${from}`, '').subscribe(response => {
      this.isOtpSent = response == 'Success';
      // this._swal2.success({ title: reSend ? `OTP is resented to ${mobileNo} number.` : `OTP is sent to ${mobileNo} number.` })
    });
  }

  verifyOtp(otp) {
    this.ApiService.get(`verifyOtp?otp=${otp}`, '').subscribe(response => {
      this.isValidOtp = response;
      if (response) {
        this.postEnquiry();
      }
    });
  }

  groupBy(array, prop) {
    return (array || []).reduce(function (groups, item) {
      let val = item[prop];
      groups[val] = groups[val] || [];
      groups[val].push(item);
      return groups;
    }, {});
  }

  average(array, prop) {
    if (prop) {
      return ((array || []).reduce(function (a, b) { return a + b[prop]; }, 0) / (array || []).length);
    } else {
      return ((array || []).reduce(function (a, b) { return a + b; }) / (array || []).length);
    }
  }
}
