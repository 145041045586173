﻿import { Base } from './Base';
import { Category } from './Category';
import { CompanyService } from './CompanyService';

export class Service extends Base {
    // TITLE
    public title: string = null;
    // DESCRIPTION
    public description: string = null;
    // WHITELOGO
    public whiteLogo: string = null;
    // GREENLOGO
    public greenLogo: string = null;
    // CATEGORYID
    public categoryId: number = 0;
    // CATEGORY
    public category: Category = null;
    // COMPANYSERVICES
    public companyServices: CompanyService[] = [];
}