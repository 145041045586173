﻿import { Base } from './Base';
import { CompanyService } from './CompanyService';

export class City extends Base {
    // NAME
    public name: string = null;
    // DESCRIPTION
    public description: string = null;
    // REASONDESCRIPTION
    public reasonDescription: string = null;
    // OTHERDESCRIPTION
    public otherDescription: string = null;
    // COMPANYSERVICES
    public companyServices: CompanyService[] = [];
}