import { Component, OnInit } from '@angular/core';
import { Service } from '../shared/modals/'
import { ServiceService } from '../shared/services/';
import { config } from '../app.config';
@Component({
  selector: 'package',
  styleUrls: ['./package.component.css'],
  templateUrl: './package.component.html'
})
export class PackageComponent implements OnInit {
  packages: any[] = [{
    title: 'Trial Pack',
    price: 5000,
    bonus: 10,
    netValue: 5500,
    netCredit: 550
  }, {
    title: 'Starter',
    price: 10000,
    bonus: 15,
    netValue: 11500,
    netCredit: 1150
  }, {
    title: 'Premium',
    price: 15000,
    bonus: 20,
    netValue: 18000,
    netCredit: 1800
  }];;
  URL: string = config.URL;

  constructor() {
  }

  ngOnInit() {
  }
}
