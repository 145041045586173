import { Component, OnInit } from '@angular/core';
import { Service } from '../shared/modals/'
import { ServiceService } from '../shared/services/';
import { config } from '../app.config';
@Component({
  selector: 'services',
  styleUrls: ['./services.component.css'],
  templateUrl: './services.component.html'
})
export class ServicesComponent implements OnInit {
  services: Service[] = [];
  URL: string = config.URL;

  constructor(private serviceService: ServiceService) {
  }

  ngOnInit() {
    this.serviceService.getAll().subscribe(services => this.services = services);
  }
}
