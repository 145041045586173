import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { OwlCarousel } from 'ngx-owl-carousel';
import { config } from '../app.config';

import { City, Service, Company, CompanyService, Enquiry, ServiceType, CategoryFilter } from '../shared/modals';
import { ApiService } from '../shared/services/';
import { SweetAlertService } from 'ngx-sweetalert2';
import { MetaService } from 'ng2-meta';
import { SlugifyPipe } from 'angular-pipes';

@Component({
  selector: 'home',
  styleUrls: ['./home.component.css'],
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {
  @ViewChild('sliderHome') sliderHome: OwlCarousel;
  @ViewChild('sliderServices') sliderServices: OwlCarousel;
  fun() {
    this.sliderHome.next([200]);
    this.sliderServices.next([200]);
    //duration 200ms
  }

  slides: any = [
    {
      image: '/assets/images/slide_01.jpg',
      title: 'Get Reliable Relocation Service',
      description: 'Quality shifting at pocket-friendly price'
    },
    {
      image: '/assets/images/slide_01.jpg',
      title: 'Get Reliable Relocation Service',
      description: 'Quality shifting at pocket-friendly price'
    },
    {
      image: '/assets/images/slide_01.jpg',
      title: 'Get Reliable Relocation Service',
      description: 'Quality shifting at pocket-friendly price'
    }, {
      image: '/assets/images/slide_01.jpg',
      title: 'Get Reliable Relocation Service',
      description: 'Quality shifting at pocket-friendly price'
    }
  ];

  URL: string = config.URL;

  cities: City[] = [];
  fromCities: City[] = [];
  toCities: City[] = [];
  services: Service[] = [];
  testimonials: any[] = [];
  serviceTypes: ServiceType[] = [];
  enquiry: Enquiry;
  categoryFilters: CategoryFilter[] = [];
  tempfilterValues: any = [];
  tempFiletrCheckbox: any = {};

  isOtpSent: boolean = false;
  isValidOtp: boolean = true;
  disableShiftType: boolean = true;
  cityTypeaheadNoResults: boolean = false;
  fromCityTypeaheadNoResults: boolean = false;
  toCityTypeaheadNoResults: boolean = false;

  minDate = new Date();

  constructor(
    // private metaService: MetaService,
    private slugifyPipe: SlugifyPipe,
    private router: Router, 
    private ApiService: ApiService, 
    // private _swal2: SweetAlertService
  ) {
    this.minDate.setDate(this.minDate.getDate() + 1);
    this.enquiry = new Enquiry();
    this.enquiry.shiftingDate = this.minDate;
  }

  gotoCity(city) {
    document.getElementById("closeChooseLocationModal").click();
    this.router.navigate(['/city/' + this.slugifyPipe.transform(city.item.name)]);
  }

  setMovingFromId(city) {
    this.enquiry.movingFromId = city.item.id;
    if(this.enquiry.serviceTypeId == 1){
      this.toCities = this.cities.filter(item => city.item.id != item.id);
    }
  }

  setMovingToId(city) {
    this.enquiry.movingToId = city.item.id;
    if(this.enquiry.serviceTypeId == 1){
      this.fromCities = this.cities.filter(item => city.item.id != item.id);
    }
  }

  onChangeCityTypeahead(e: boolean): void {
    this.cityTypeaheadNoResults = e;
  }

  onChangeFromCityTypeahead(e: boolean): void {
    this.fromCityTypeaheadNoResults = e;
  }

  onChangeToCityTypeahead(e: boolean): void {
    this.toCityTypeaheadNoResults = e;
  }

  ngOnInit() {
    this.ApiService.get('Services', '').subscribe(Services => this.services = Services);
    this.ApiService.get('Testimonials', '').subscribe(Testimonials => this.testimonials = Testimonials);

    this.tempfilterValues.forEach(element => {
      this.tempFiletrCheckbox[element.filter.id + '' + element.value.id] = true;
    });

    this.ApiService.get('Cities', '').subscribe(cities => {
      this.cities = cities;
      this.fromCities = cities;
      this.toCities = cities;
    });
    this.ApiService.get('ServiceTypes', '').subscribe(ServiceTypes => this.serviceTypes = ServiceTypes);

    this.ApiService.get('CategoryFilters', '').subscribe(CategoryFilters => {
      this.ApiService.get('CategoryFilterValues', '').subscribe(CategoryFilterValues => {
        this.categoryFilters = CategoryFilters;
        CategoryFilterValues.forEach(CategoryFilterValue => {
          let categoryFilter = this.categoryFilters.find(categoryFilter => categoryFilter.id == CategoryFilterValue.categoryFilterId);
          categoryFilter.values = categoryFilter.values || [];
          categoryFilter.values.push(CategoryFilterValue);
        });;
      });
    });
  }

  onChangeFilterValue(isChecked, value, filter) {
    this.disableShiftType = true;
    if (isChecked) {
      this.tempfilterValues.push({ value: value, filter: filter });
    } else {
      this.tempfilterValues.splice(this.tempfilterValues.indexOf({ value: value, filter: filter }), 1);
    }
    for (let tmp in this.tempFiletrCheckbox) {
      if (this.tempFiletrCheckbox[tmp]) {
        this.disableShiftType = false;
        break;
      }
    }
    this.enquiry.filterValues = JSON.stringify(this.tempfilterValues);
  }

  postEnquiry() {
    if (this.enquiry.serviceTypeId == 3) {
      this.enquiry.movingToId = this.enquiry.movingFromId;
    }
    this.enquiry.isActive = true;
    this.ApiService.post('Enquiries', this.enquiry).subscribe(Enquiry => {
      document.getElementById("closeOtpVerficationModal").click();
      // this._swal2.success({ title: 'Your enquiry is submitted successfully.' }).then(() => {
      // });
    });
  }

  sendOtp(mobileNo, email, from, reSend) {
    this.ApiService.get(`generateOtp?mobileNo=${mobileNo}&$email=${email}&from=${from}`, '').subscribe(response => {
      this.isOtpSent = response == 'Success';
      // this._swal2.success({ title: reSend ? `OTP is resented to ${mobileNo} number.` : `OTP is sent to ${mobileNo} number.` })
    });
  }

  verifyOtp(otp) {
    this.ApiService.get(`verifyOtp?otp=${otp}`, '').subscribe(response => {
      this.isValidOtp = response;
      if (response) {
        this.postEnquiry();
      }
    });
  }
}

