import { Component, OnInit } from "@angular/core";
import { FileUploader } from "ng2-file-upload";
import { config } from "../app.config";
import { ApiService } from "../shared/services/index";
import { City, Service, CompanyAddress } from "../shared/modals/index";
import { SweetAlertService } from "ngx-sweetalert2";

@Component({
  selector: "profile",
  styleUrls: ["./profile.component.css"],
  templateUrl: "./profile.component.html"
})
export class ProfileComponent implements OnInit {
  user: any = {};
  userInfo: any = {};
  cities: any[] = [];
  services: any[] = [];
  addresses: any[] = [{}];
  companyDetail: any = {};
  tempCityId: any = {};
  selectedCities: any = [];
  selectedServices: any = [];

  cityTypeaheadNoResults: boolean = false;

  logoUploader: FileUploader;
  bannerUploader: FileUploader;

  constructor(
    private ApiService: ApiService,
    // private _swal2: SweetAlertService
  ) {
    this.logoUploader = new FileUploader({
      url: config.FILE_UPLOAD_URL
    });

    this.bannerUploader = new FileUploader({
      url: config.FILE_UPLOAD_URL
    });

    this.logoUploader.onAfterAddingFile = fileItem => {
      fileItem.withCredentials = false;
      this.logoUploader.uploadAll();
      this.logoUploader.response.subscribe(res => {
        this.companyDetail.logo = res.fileName;
        this.logoUploader.clearQueue();
      });
    };

    this.bannerUploader.onAfterAddingFile = fileItem => {
      fileItem.withCredentials = false;
      this.bannerUploader.uploadAll();
      this.bannerUploader.response.subscribe(res => {
        this.companyDetail.banner = res.fileName;
        this.bannerUploader.clearQueue();
      });
    };
  }

  ngOnInit() {
    this.ApiService.get("Cities", "").subscribe(cities => {
      this.cities = cities;
    });
    this.ApiService.get("Services", "").subscribe(services => {
      this.services = services.map(service => {
        return { text: service.title, id: service.id };
      });
    });
    this.getUserDetails();
  }

  getUserDetails() {
    this.ApiService.get("Account/UserInfo", "").subscribe(userInfo => {
      this.userInfo = userInfo;
      this.ApiService.get("Users", { id: userInfo.userId }).subscribe(user => {
        this.user = user;
        this.getCompanyDetails();
      });
    });
  }

  getCompanyDetails() {
    this.ApiService.get("Companies/" + this.user.companyId, "").subscribe(
      userCompany => {
        this.companyDetail = userCompany;
        if (
          this.companyDetail.companyAddresses &&
          this.companyDetail.companyAddresses.length
        ) {
          this.companyDetail.companyAddresses.forEach((element, index) => {
            this.tempCityId[index] = element.city.name;
          });
        } else {
          this.companyDetail.companyAddresses = [{}];
        }
        this.selectedServices = Array.from(
          new Set(
            (this.companyDetail.companyServices || []).map(companyService => {
              return {
                id: companyService.service.id,
                text: companyService.service.title,
                children: [{ id: companyService.id }]
              };
            })
          )
        );
      }
    );
  }

  onChangeCities(value: any): void {
    this.selectedCities = value;
  }

  onRefreshServices(value: any) {
    // this.selectedServices = value;
  }

  onChangeServices(value: any, from: string): void {
    if (from == "add") {
      var companyService = {
        companyId: this.companyDetail.id,
        serviceId: value.id
      };
      this.ApiService.post("CompanyServices", companyService).subscribe(
        service => {
          this.getCompanyDetails();
        }
      );
    } else if (from == "remove") {
      let csId = this.selectedServices.find(a => a.id == value.id);
      if (csId && csId.children[0]) {
        this.ApiService.delete(
          "CompanyServices/" + csId.children[0].id,
          ""
        ).subscribe(companyAddress => {
          this.getCompanyDetails();
        });
      }
    }
  }

  onChangeCityTypeahead(e: boolean): void {
    this.cityTypeaheadNoResults = e;
  }

  addMoreAddress(ngForm, address) {
    ngForm._submitted = true;
    if (ngForm.valid) {
      this.companyDetail.companyAddresses.push({});
    }
  }

  removeAddress(index) {
    if (this.companyDetail.companyAddresses.length > 1) {
      this.companyDetail.companyAddresses.splice(index, 1);
    }
  }

  hasHeadOffice() {
    return ((this.companyDetail || {}).companyAddresses || []).some(
      companyAddress => {
        return !!companyAddress.isForHeadOffice;
      }
    );
  }

  setCity(city, index) {
    this.companyDetail.companyAddresses[index].cityId = city.item.id;
  }

  updateProfile(profileForm) {
    if (profileForm.valid) {
      this.companyDetail.companyAddresses.forEach(companyAddress => {
        companyAddress.companyId = this.companyDetail.id;
        if (companyAddress.id) {
          delete companyAddress.city;
          delete companyAddress.company;
          this.ApiService.put(
            "CompanyAddresses/" + companyAddress.id,
            companyAddress
          ).subscribe(companyAddress => {
            // this._swal2
            //   .success({ title: "Your profile updated successfully." })
            //   .then(() => {
            //     console.log(companyAddress);
            //   });
          });
        } else {
          this.ApiService.post("CompanyAddresses", companyAddress).subscribe(
            companyAddress => {
              // this._swal2
              //   .success({ title: "Your profile updated successfully." })
              //   .then(() => {
              //     console.log(companyAddress);
              //   });
            }
          );
        }
      });
    }
  }
}
