﻿import { Base } from './Base';
import { CompanyService } from './CompanyService';
import { CompanyServiceReview } from './CompanyServiceReview';
import { CompanyAddress } from './CompanyAddress';
import { User } from './User';
import { CompanyServiceType } from './CompanyServiceType';

export class Company extends Base {
    // TITLE
    public title: string = null;
    // SUMMARY
    public summary: string = null;
    // DESCRIPTION
    public description: string = null;
    // STATUS
    public status: string = null;
    // WEBSITEURL
    public websiteUrl: string = null;
    // LOGO
    public logo: string = null;
    // BANNER
    public banner: string = null;
    // COMPANYSERVICES
    public companyServices: CompanyService[] = [];
    public companyServiceReviews: CompanyServiceReview[] = [];
    public companyAddresses: CompanyAddress[] = [];
    // USERS
    public users: User[] = [];
    // COMPANYSERVICETYPES
    public companyServiceTypes: CompanyServiceType[] = [];
}
