import { NgtUniversalModule } from '@ng-toolkit/universal';
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router';
import { rootRouterConfig } from './app.routes';
import { AppComponent } from './app.component';
import { GithubService } from './github/shared/github.service';
import { ApiService, BlogService, ServiceService, CityService, CompanyService } from './shared/services/';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { SelectModule } from 'ng2-select';
import { VSChecklistModule } from 'ng2-vs-checklist';
import { FileUploadModule } from 'ng2-file-upload';
import { TypeaheadModule, BsDatepickerModule } from 'ngx-bootstrap';
import { SweetAlertService } from 'ngx-sweetalert2';
import { OwlModule } from 'ngx-owl-carousel';
import { NgStringPipesModule, SlugifyPipe } from 'angular-pipes';
import { MetaModule, MetaConfig } from 'ng2-meta';

import { AboutComponent } from './about/about.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { PrivacyPolicyComponent } from './privacyPolicy/privacyPolicy.component';
import { TermsConditionsComponent } from './termsConditions/termsConditions.component';
import { HomeComponent } from './home/home.component';
import { RepoBrowserComponent } from './github/repo-browser/repo-browser.component';
import { RepoListComponent } from './github/repo-list/repo-list.component';
import { RepoDetailComponent } from './github/repo-detail/repo-detail.component';
import { CommonModule } from '@angular/common';
import { ContactComponent } from './contact/contact.component';

import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ProfileComponent } from './profile/profile.component';
import { ServicesComponent } from './services/services.component';
import { PackageComponent } from './package/package.component';
import { EnquiryComponent } from './enquiry/enquiry.component';
import { LeadsComponent } from './leads/leads.component';
import { CityDetailComponent } from './city-detail/city-detail.component';
import { CompaniesComponent } from './companies/companies.component';
import { CompanyDetailComponent } from './company-detail/company-detail.component';
import { BlogComponent } from './blog/blog.component';
import { BlogDetailComponent } from './blog-detail/blog-detail.component';

const metaConfig: MetaConfig = {
  //Append a title suffix such as a site name to all titles
  //Defaults to false
  useTitleSuffix: true,
  defaults: {
    title: 'Get Reliable Packers and Movers near You | Best Relocation Service in India',
    titleSuffix: ' - Oakfeat',
    description: 'Get reliable packers and movers near you at oakfeat.com. We provide best relocation service at the affordable price in the major city of India. Visit us for more information.',
    keywords: 'packers and movers',
    'og:image': 'http://example.com/default-image.png'
  }
};
@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    DisclaimerComponent,
    PrivacyPolicyComponent,
    TermsConditionsComponent,
    RepoBrowserComponent,
    RepoListComponent,
    RepoDetailComponent,
    HomeComponent,
    ContactComponent,
    LoginComponent,
    RegisterComponent,
    ProfileComponent,
    ServicesComponent,
    PackageComponent,
    EnquiryComponent,
    LeadsComponent,
    CityDetailComponent,
    CompaniesComponent,
    CompanyDetailComponent,
    BlogComponent,
    BlogDetailComponent
  ],
  imports: [
    CommonModule,
    NgtUniversalModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgStringPipesModule,
    SelectModule,
    VSChecklistModule,
    FileUploadModule,
    OwlModule,
    // MetaModule.forRoot(metaConfig),
    TypeaheadModule.forRoot(),
    BsDatepickerModule.forRoot(),
    RouterModule.forRoot(rootRouterConfig)
  ],
  providers: [
    GithubService,
    SlugifyPipe,
    ApiService,
    BlogService,
    ServiceService,
    CityService,
    CompanyService,
    // SweetAlertService
  ]
})
export class AppModule {

}