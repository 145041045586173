import { Component } from '@angular/core';
import { Router } from "@angular/router";
import { ApiService } from '../shared/services/index';
import { SweetAlertService } from 'ngx-sweetalert2';

@Component({
  selector: 'register',
  styleUrls: ['./register.component.css'],
  templateUrl: './register.component.html'
})
export class RegisterComponent {
  user: any = {};
  company: any = {};
  isValidOtp:boolean=true;
  error:string='';

  constructor(private router: Router, private ApiService: ApiService) {
  }

  onRegister(form) {
    if(form.valid){
      this.sendOtp(this.user.mobileNo, this.user.email, 1, false);
      document.getElementById("openOtpVerificationModal").click();
    }
  }

  sendOtp(mobileNo, email, from, reSend) {
    this.ApiService.get(`generateOtp?mobileNo=${mobileNo}&$email=${email}&&from=${from}`, '').subscribe(response => {
      // this._swal2.success({ title: reSend ? `OTP is resented to ${mobileNo} number.` : `OTP is sent to ${mobileNo} number.` });
    });
  }

  verifyOtp(otp) {
    this.ApiService.get(`verifyOtp?otp=${otp}`, '').subscribe(response => {
      this.isValidOtp = response;
      if (response) {
        this.ApiService.post("Account/Register",this.user).subscribe(res => {
          document.getElementById("closeOtpVerificationModal").click();
          // this._swal2.success({ title: 'Your profile updated successfully.' }).then(() => {
          //   this.router.navigate(['/login']);
          // });
        },
        err => {
          this.error = JSON.parse(err._body).message;
          document.getElementById("closeOtpVerificationModal").click();
          console.log(err);
        }
      );
      }
    });
  }
}
