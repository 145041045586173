import { Component } from '@angular/core';
import { Router } from "@angular/router";
import { ApiService } from '../shared/services/index'
import { User } from '../shared/modals/index';

@Component({
  selector: 'login',
  styleUrls: ['./login.component.css'],
  templateUrl: './login.component.html'
})
export class LoginComponent {
  user: any = {};
  error: string = '';

  constructor(private router: Router, private ApiService: ApiService) {
  }

  onLogin() {
    this.error = '';
    this.user.grant_type = 'password';
    this.ApiService.login(this.user).subscribe(res => {
      if (res.error && res.error_description) {
        this.error = res.error_description;
      } else {
        localStorage.setItem('token', JSON.stringify(res));
        this.ApiService.get('Account/UserInfo', null).subscribe(data => {
          localStorage.setItem('userInfo', JSON.stringify(data));
          this.router.navigate(['/']);
        })
      }
    }, error => {
      this.error = JSON.parse(error._body).error_description;;
    });
  }
}
