﻿import { Base } from './Base';
import { Category } from './Category';
import { CategoryFilterValue } from './CategoryFilterValue';

export class CategoryFilter extends Base {
    // CATEGORYID
    public categoryId: number = 0;
    // CATEGORY
    public category: Category = null;
    // TITLE
    public title: string = null;
    // DESCRIPTION
    public description: string = null;
    // VALUES
    public values: CategoryFilterValue[] = [];
}
