﻿import { Base } from './Base';
import { Enquiry } from './Enquiry';

export class Lead extends Base {
    // ENQUIRYID
    public enquiryId: number = 0;
    // ENQUIRY
    public enquiry: Enquiry = null;
    // USERID
    public userId: string = null;
    // USER
    public user: string = null;
    // RESPONSEDATE
    public responseDate: Date = new Date();
    // STATUS
    public status: number = 0;
    // LEADID
    public leadId: string = null;
    // CREDITCOST
    public creditCost: number = 0;
}