export const config = {
  production: false,
  URL: 'https://api.oakfeat.com/',
  API_URL: 'https://api.oakfeat.com/Api/',
  FILE_UPLOAD_URL: 'https://api.oakfeat.com/Api/FileUpload',
  UPLOADS_URL: 'https://api.oakfeat.com/Uploads',
  OPTIMIZATION: {
    'pune': {
      TITLE: 'Get Packers and Movers for Relocation in Pune',
      DESCRIPTION: 'Are you searching packers and movers services in Pune? Visit oakfeat.com and get most trusted and verified packers and movers for office and Household Shifting at your budget.'
    },
    'ahmedabad': {
      TITLE: 'Trusted Packers and Movers in Ahmedabad',
      DESCRIPTION: 'Find professional packers and movers in Ahmedabad at oakfeat.com. We provide perfect packers and movers for relocation, shifting, loading, unloading and packing service at best prices.'
    },
    'new-delhi': {
      TITLE: 'Verified Packers and Movers List in Delhi',
      DESCRIPTION: 'Get best packers and movers at Oakfeat.com for household shifting in New Delhi. We offer safe and reliable relocation services at expectable price.'
    },
    'jaipur': {
      TITLE: 'Jaipur Professional Packers & Movers',
      DESCRIPTION: 'Looking for perfect packers and movers for packing and moving services in Jaipur? Visit oakfeat.com and get most trusted packers and movers for office and home shifting.'
    },
    'noida': {
      TITLE: 'The Best Packers and Movers Service in noida',
      DESCRIPTION: 'Oakfeat provides professional packers and movers for relocation, shifting, loading and unloading services in Noida. Contact us for more information.'
    },
    'kolkata': {
      TITLE: 'Top List of Kolkata Packers and Movers',
      DESCRIPTION: 'Are you looking list of top packers and movers services in Kolkata? Look at oakfeat.com for most trusted packers and movers for office shifting at your budget.'
    },
    'hyderabad': {
      TITLE: 'Top Packers and Movers in Hyderabad List',
      DESCRIPTION: 'Hire reliable movers and packers at oakfeat.com. We provide best movers and packers for relocation at the affordable price in Hyderabad. Visit us for more details.'
    },
    'gurgaon': {
      TITLE: 'Best Movers and Packers in Gurgaon',
      DESCRIPTION: 'Oakfeat is one stop solution for packers and movers in Gurgaon. We provide best services of relocation with any damage and hassle at expectable price.'
    },
    'chennai': {
      TITLE: 'Top and Reliable Movers and Packers in Chennai',
      DESCRIPTION: 'Find professional and verified packers and movers at oakfeat.com in Chennai. You find perfect packers and movers services for relocation and shifting at affordable price.'
    },
    'chandigarh': {
      TITLE: 'List of Top Packers and Movers in Chandigarh',
      DESCRIPTION: 'Oakfeat offers verified and trusted packers and movers for office and House shifting in Chandigarh. Contact us for more information and estimate cost.'
    },
    'bangalore': {
      TITLE: 'Top Highly Rated Verified Packers and Movers in Bangalore',
      DESCRIPTION: 'If you are searching best packers and movers in Bangalore. Visit oakfeat.com to get most trusted and reliable packers and movers for Household Shifting at lowest price.'
    },
    '2': {
      TITLE: 'Get Packers and Movers for Relocation in Pune',
      DESCRIPTION: 'Are you searching packers and movers services in Pune? Visit oakfeat.com and get most trusted and verified packers and movers for office and Household Shifting at your budget.'
    },
    '3': {
      TITLE: 'Trusted Packers and Movers in Ahmedabad',
      DESCRIPTION: 'Find professional packers and movers in Ahmedabad at oakfeat.com. We provide perfect packers and movers for relocation, shifting, loading, unloading and packing service at best prices.'
    },
    '4': {
      TITLE: 'Verified Packers and Movers List in Delhi',
      DESCRIPTION: 'Get best packers and movers at Oakfeat.com for household shifting in New Delhi. We offer safe and reliable relocation services at expectable price.'
    },
    '5': {
      TITLE: 'Jaipur Professional Packers & Movers',
      DESCRIPTION: 'Looking for perfect packers and movers for packing and moving services in Jaipur? Visit oakfeat.com and get most trusted packers and movers for office and home shifting.'
    },
    '6': {
      TITLE: 'The Best Packers and Movers Service in noida',
      DESCRIPTION: 'Oakfeat provides professional packers and movers for relocation, shifting, loading and unloading services in Noida. Contact us for more information.'
    },
    '7': {
      TITLE: 'Top List of Kolkata Packers and Movers',
      DESCRIPTION: 'Are you looking list of top packers and movers services in Kolkata? Look at oakfeat.com for most trusted packers and movers for office shifting at your budget.'
    },
    '9': {
      TITLE: 'Top Packers and Movers in Hyderabad List',
      DESCRIPTION: 'Hire reliable movers and packers at oakfeat.com. We provide best movers and packers for relocation at the affordable price in Hyderabad. Visit us for more details.'
    },
    '10': {
      TITLE: 'Best Movers and Packers in Gurgaon',
      DESCRIPTION: 'Oakfeat is one stop solution for packers and movers in Gurgaon. We provide best services of relocation with any damage and hassle at expectable price.'
    },
    '11': {
      TITLE: 'Top and Reliable Movers and Packers in Chennai',
      DESCRIPTION: 'Find professional and verified packers and movers at oakfeat.com in Chennai. You find perfect packers and movers services for relocation and shifting at affordable price.'
    },
    '12': {
      TITLE: 'List of Top Packers and Movers in Chandigarh',
      DESCRIPTION: 'Oakfeat offers verified and trusted packers and movers for office and House shifting in Chandigarh. Contact us for more information and estimate cost.'
    },
    '13': {
      TITLE: 'Top Highly Rated Verified Packers and Movers in Bangalore',
      DESCRIPTION: 'If you are searching best packers and movers in Bangalore. Visit oakfeat.com to get most trusted and reliable packers and movers for Household Shifting at lowest price.'
    }
  }
};
