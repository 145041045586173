﻿import { Base } from './Base';
import { CategoryFilter } from './CategoryFilter';

export class CategoryFilterValue extends Base {
    // CATEGORYFILTERID
    public categoryFilterId: number = 0;
    // CATEGORYFILTER
    public categoryFilter: CategoryFilter = null;
    // VALUE
    public value: string = null;
    // TYPE
    public type: string = null;
    // PRICE
    public price: number = 0;
}