import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import CustomValidators from '../forms/CustomValidators';
declare var google: any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact-component.css']
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup;
  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, CustomValidators.validateEmail]],
      content: ['', [Validators.required, Validators.minLength(10)]]
    });

    var mapCanvas = document.getElementById("google_map");
    var mapOptions = {
      center: new google.maps.LatLng(51.5, -0.2),
      zoom: 10
    };
    var map = new google.maps.Map(mapCanvas, mapOptions);
  }
  submitForm(): void {
    console.log(this.contactForm);
  }
}
