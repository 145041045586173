﻿import { Base } from './Base';
import { Service } from './Service';
import { CategoryFilter } from './CategoryFilter';

export class Category extends Base {
    // TITLE
    public title: string = null;
    // DESCRIPTION
    public description: string = null;
    // LOGO
    public logo: string = null;
    // SERVICES
    public services: Service[] = [];
    // FILTERS
    public filters: CategoryFilter[] = [];
}