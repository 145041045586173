import { Component, OnInit } from "@angular/core";
import {
  City,
  Service,
  CompanyService,
  ServiceType,
  Enquiry,
  Lead
} from "../shared/modals/index";
import { ActivatedRoute } from "@angular/router";
import { ApiService } from "../shared/services/index";
import * as _ from "lodash";

@Component({
  selector: "leads",
  styleUrls: ["./leads.component.css"],
  templateUrl: "./leads.component.html"
})
export class LeadsComponent implements OnInit {
  cities: City[] = [];
  serviceTypes: ServiceType[] = [];
  leads: Lead[] = [];
  companyServices: CompanyService[] = [];
  filters: any;
  isloading: boolean = false;

  service: number;

  constructor(private route: ActivatedRoute, private ApiService: ApiService) {
    this.service = +this.route.snapshot.params.serviceId;
  }

  ngOnInit() {
    this.clearFilters();
    this.ApiService.get("Cities", "").subscribe(
      cities => (this.cities = cities)
    );
    this.ApiService.get("ServiceTypes", "").subscribe(
      res => (this.serviceTypes = res)
    );
  }

  getShiftingType(filters){
    let shiftingTypes = JSON.parse((filters || {})) || [];
    return shiftingTypes.map(item => {
      return item.value.value;
    }).join(', ');
  }

  getEnquiry() {
    this.isloading = true;
    let params = JSON.parse(JSON.stringify(this.filters));
    params.serviceType = (params.serviceType || []).join(',');
    params.from = (params.from || []).join(',');
    
    this.ApiService.get("leads", params).subscribe(res => {
      this.leads = res.result || [];
      this.isloading = false;
    });
  }

  clearFilters() {
    this.filters = {
      pageNumber: 1,
      pageSize: 100,
      serviceType: [],
      serviceId: "",
      from: [],
      to: "",
      date: ""
    };
    this.getEnquiry();
  }

  buyLead(enq) {
    var newLead = {
      enquiryId: enq.id,
      userId: 1,
      companyId: 1,
      responseDate: new Date(),
      status: 1,
      leadId: enq.leadId,
      creditCost: enq.minCredit,
      createdDate: new Date(),
      isActive: true
    };
    this.ApiService.post("leads", newLead).subscribe(res => {
      console.log(res);
    });
  }
}
