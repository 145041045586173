import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Service } from '../modals/'

@Injectable()
export class ServiceService {
  constructor(private ApiService: ApiService) { }

  get(id: number) {
    return this.ApiService.get(`Services/${id}`, '');
  }

  getAll() {
    return this.ApiService.get('Services', '');
  }

  put(service: Service) {
    return this.ApiService.put('Services', service);
  }

  post(service: Service) {
    return this.ApiService.post('Services', service);
  }
}
