﻿import { Base } from './Base';
import { CompanyService } from './CompanyService';
import { CompanyAddress } from './CompanyAddress';

export class CompanyServiceArea extends Base {
    // USERID
    public userId: string = null;
    // USER
    public user: string = null;
    // COMPANYSERVICEID
    public companyServiceId: number = 0;
    // COMPANYSERVICE
    public companyService: CompanyService = null;
    // COMPANYADDRESSID
    public companyAddressId: number = 0;
    // COMPANYADDRESS
    public companyAddress: CompanyAddress = null;
    // FAVOURITEPLACES
    public favouritePlaces: string = null;
    // CHARGEPERKG
    public chargePerKg: number = 0;
    // CHARGEPERKM
    public chargePerKm: number = 0;
    // CHARGEPERHOUR
    public chargePerHour: number = 0;
    // CHARGEPERDAY
    public chargePerDay: number = 0;
    // FULLCHARGE
    public fullCharge: number = 0;
    // TRANSPARENTCHARGE
    public transparentCharge: number = 0;
    // PRICE
    public price: number = 0;
}
