import { Component, OnInit } from '@angular/core';
import { ApiService } from './shared/services/index';

@Component({
  selector: 'app-root',
  styleUrls: ['./app.component.css'],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {

  isLoggedIn: Function;
  services: any = [];

  constructor(private ApiService: ApiService) {
    this.isLoggedIn = ApiService.isLoggedIn;
  }

  ngOnInit() {
    this.ApiService.get('Services', '').subscribe(services => this.services = services);
  }

  onActivate(event) {
    window.scroll(0, 0);
  }

  logout() {
    localStorage.removeItem('token');
    location.pathname = '/login'
  }

  showHideFooter() {
    return !(location.pathname == '/login' || location.pathname == '/register');
  }

}
