import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Blog } from '../shared/modals/';
import { BlogService } from '../shared/services/';
@Component({
  selector: 'blog-detail',
  styleUrls: ['./blog-detail.component.css'],
  templateUrl: './blog-detail.component.html'
})
export class BlogDetailComponent implements OnInit {
  blog: Blog;

  constructor(private route: ActivatedRoute, private blogService: BlogService) {
    this.blog = new Blog();
  }

  ngOnInit() {
    this.blogService.get(+this.route.snapshot.params.blogId).subscribe(blog => {
      this.blog = blog;
    });
  }
}
