import {Component, OnInit} from '@angular/core';
import { MetaService } from 'ng2-meta';

@Component({
  selector: 'about',
  styleUrls: ['./about.component.css'],
  templateUrl: './about.component.html'
})
export class AboutComponent implements OnInit {
  constructor(){}

  ngOnInit() {
    // setTimeout(() => {
    //   this.metaService.setTitle('About - BY PROGRAMATICALLY');
    //   this.metaService.setTag('og:image','this.product.imageURL');
    // }); //example
  }
}
