import { Routes } from '@angular/router';

import { AboutComponent } from './about/about.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { PrivacyPolicyComponent } from './privacyPolicy/privacyPolicy.component';
import { TermsConditionsComponent } from './termsConditions/termsConditions.component';
import { HomeComponent } from './home/home.component';
import { RepoBrowserComponent } from './github/repo-browser/repo-browser.component';
import { RepoListComponent } from './github/repo-list/repo-list.component';
import { RepoDetailComponent } from './github/repo-detail/repo-detail.component';
import { ContactComponent } from './contact/contact.component';

import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ProfileComponent } from './profile/profile.component';
import { ServicesComponent } from './services/services.component';
import { PackageComponent } from './package/package.component';
import { EnquiryComponent } from './enquiry/enquiry.component';
import { LeadsComponent } from './leads/leads.component';
import { CityDetailComponent } from './city-detail/city-detail.component';
import { CompaniesComponent } from './companies/companies.component';
import { CompanyDetailComponent } from './company-detail/company-detail.component';
import { BlogComponent } from './blog/blog.component';
import { BlogDetailComponent } from './blog-detail/blog-detail.component';

export const rootRouterConfig: Routes = [
  // { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: '', component: HomeComponent, data: {
      meta: {
        title: 'Get Reliable Packers and Movers near You | Best Relocation Service in India',
        description: 'Get reliable packers and movers near you at oakfeat.com. We provide best relocation service at the affordable price in the major city of India. Visit us for more information.'
      }
    }
  },
  {
    path: 'home', component: HomeComponent, data: {
      meta: {
        title: 'Get Reliable Packers and Movers near You | Best Relocation Service in India',
        description: 'Get reliable packers and movers near you at oakfeat.com. We provide best relocation service at the affordable price in the major city of India. Visit us for more information.'
      }
    }
  },
  { path: 'about', component: AboutComponent, data: {
    meta: {
      title: 'About',
      description: 'Description of the about page'
    }
  } },
  { path: 'disclaimer', component: DisclaimerComponent, data: {
    meta: {
      title: 'Disclaimer',
      description: 'Description of the disclaimer page'
    }
  } },
  { path: 'privacy-policy', component: PrivacyPolicyComponent, data: {
    meta: {
      title: 'Privacy Policy',
      description: 'Description of the privacy-policy page'
    }
  } },
  { path: 'terms-conditions', component: TermsConditionsComponent, data: {
    meta: {
      title: 'Terms & Conditions',
      description: 'Description of the terms-conditions page'
    }
  } },
  { path: 'login', component: LoginComponent, data: {
    meta: {
      title: 'Login',
      description: 'Description of the login page'
    }
  } },
  { path: 'register', component: RegisterComponent, data: {
    meta: {
      title: 'Register',
      description: 'Description of the register page'
    }
  } },
  { path: 'profile', component: ProfileComponent, data: {
    meta: {
      title: 'Profile',
      description: 'Description of the profile page'
    }
  } },
  { path: 'services', component: ServicesComponent, data: {
    meta: {
      title: 'Services',
      description: 'Description of the services page'
    }
  } },
  { path: 'package', component: PackageComponent, data: {
    meta: {
      title: 'Packages',
      description: 'Description of the package page'
    }
  } },
  { path: 'service/:serviceId', component: CompaniesComponent, data: {
    meta: {
      title: 'Service',
      description: 'Description of the service page'
    }
  } },
  { path: 'leads', component: LeadsComponent, data: {
    meta: {
      title: 'Leads',
      description: 'Description of the leads page'
    }
  } },
  { path: 'enquiry', component: EnquiryComponent, data: {
    meta: {
      title: 'Enquiry',
      description: 'Description of the enquiry page'
    }
  } },
  { path: 'city/:cityId', component: CityDetailComponent, data: {
    meta: {
      title: 'City',
      description: 'Description of the city page'
    }
  } },
  { path: 'companies', component: CompaniesComponent, data: {
    meta: {
      title: 'Companies',
      description: 'Description of the companies page'
    }
  } },
  { path: 'company-detail/:companyId', component: CompanyDetailComponent, data: {
    meta: {
      title: 'Company Detail',
      description: 'Description of the company-detail page'
    }
  } },
  { path: 'blog', component: BlogComponent, data: {
    meta: {
      title: 'Blogs',
      description: 'Description of the blog page'
    }
  } },
  { path: 'blog/:blogId', component: BlogDetailComponent, data: {
    meta: {
      title: 'Blog',
      description: 'Description of the blog page'
    }
  } },
  {
    path: 'github', component: RepoBrowserComponent,
    children: [
      { path: '', component: RepoListComponent },
      {
        path: ':org', component: RepoListComponent,
        children: [
          { path: '', component: RepoDetailComponent },
          { path: ':repo', component: RepoDetailComponent }
        ]
      }]
  },
  { path: 'contact', component: ContactComponent, data: {
    meta: {
      title: 'Contact',
      description: 'Description of the contact page'
    }
  } }
];

