﻿import { Base } from './Base';

export class Blog extends Base {
    // TITLE
    public title: string = null;
    // TAGLINE
    public tagline: string = null;
    // SUMMARY
    public summary: string = null;
    // DESCRIPTION
    public description: string = null;
    // IMAGE
    public image: string = null;
}