﻿import { Base } from './Base';
import { City } from './City';
import { CompanyServiceArea } from './CompanyServiceArea';

export class CompanyAddress extends Base {
    // TITLE
    public title: string = null;
    // ADDRESS
    public address: string = null;
    // STATE
    public state: string = null;
    // ZIPCODE
    public zipCode: string = null;
    // MOBILENUMBER
    public mobileNumber: string = null;
    // EMERGENCYNUMBER
    public emergencyNumber: string = null;
    // PHONENUMBER
    public phoneNumber: string = null;
    // EMAIL
    public email: string = null;
    // CITYID
    public cityId: number = 0;
    // CITY
    public city: City = null;
    // COMPANYSERVICEAREAS
    public companyServiceAreas: CompanyServiceArea[] = [];
}