﻿import { Base } from './Base';
import { CompanyServiceType } from './CompanyServiceType';

export class ServiceType extends Base {
    // TITLE
    public title: string = null;
    // ALIAS
    public alias: string = null;
    // DESCRIPTION
    public description: string = null;
    // COMPANYSERVICETYPES
    public companyServiceTypes: CompanyServiceType[] = [];
}