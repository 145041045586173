﻿import { Company } from './Company';
import { City } from './City';
import { CompanyService } from './CompanyService';

export class User {
    // FIRSTNAME
    public firstName: string = null;
    // LASTNAME
    public lastName: string = null;
    // PHONENO
    public phoneNo: string = null;
    // MOBILENO
    public mobileNo: string = null;
    // STATE
    public state: string = null;
    // WEBSITEURL
    public websiteUrl: string = null;
    // ADDRESS
    public address: string = null;
    // EMERGENCYNO
    public emergencyNo: string = null;
    // PROFILEIMAGE
    public profileImage: string = null;
    // BIRTHDATE
    public birthDate: Date = new Date();
    // CREATEDDATE
    public createdDate: Date = new Date();
    // MODIFIEDDATE
    public modifiedDate: Date = null;
    // ISDELETED
    public isDeleted: boolean = false;
    // ISACTIVE
    public isActive: boolean = false;
    // COMPANYID
    public companyId: number = null;
    // COMPANY
    public company: Company = null;
    // CITYID
    public cityId: number = null;
    // CITY
    public city: City = null;
    // COMPANYSERVICES
    public companyServices: CompanyService[] = [];
}