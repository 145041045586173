﻿import { Base } from './Base';
import { ServiceType } from './ServiceType';
import { City } from './City';

export class Enquiry extends Base {
    // SERVICETYPEID
    public serviceTypeId: number = 0;
    // SERVICETYPE
    public serviceType: ServiceType = null;
    // MOVINGFROMID
    public movingFromId: number = null;
    // MOVINGFROM
    public movingFrom: City = null;
    // MOVINGTOID
    public movingToId: number = null;
    // MOVINGTO
    public movingTo: City = null;
    public movingToCity: string = '';
    // LEADID
    public leadId: string = null;
    // MOVINGFROMLOCATION
    public movingFromLocation: string = null;
    // MOVINGTOLOCATION
    public movingToLocation: string = null;
    // MOVINGFROMZIPCODE
    public movingFromZipCode: string = null;
    // MOVINGTOZIPCODE
    public movingToZipCode: string = null;
    // SHIFTINGDATE
    public shiftingDate: Date = new Date();
    // SHIFTINGTIME
    public shiftingTime: string = null;
    // MOVINGITEMS
    public movingItems: string = null;
    // FILTERVALUES
    public filterValues: string = null;
    // QUALITYTYPE
    public qualityType: number = 0;
    // CUSTOMERNAME
    public customerName: string = null;
    // MOBILENO
    public mobileNo: string = null;
    // EMAIL
    public email: string = null;
    // STATUS
    public status: number = 0;
    // ISVERIFIED
    public isVerified: boolean = false;

    public minCredit: number = 2;
}
