﻿import { Base } from './Base';
import { ServiceType } from './ServiceType';
import { CompanyService } from './CompanyService';

export class CompanyServiceType extends Base {
    // SERVICETYPEID
    public serviceTypeId: number = 0;
    // SERVICETYPE
    public serviceType: ServiceType = null;
    // COMPANYSERVICEID
    public companyServiceId: number = 0;
    // COMPANYSERVICE
    public companyService: CompanyService = null;
}