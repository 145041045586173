import { Component, OnInit } from "@angular/core";
import {
  Company,
  CompanyServiceReview,
  CompanyService,
  City
} from "../shared/modals/index";
import { ActivatedRoute } from "@angular/router";
import { ApiService } from "../shared/services/index";
import { RatingTypeEnum } from "../shared/enums/index";
import { config } from "../app.config";
import { SweetAlertService } from "ngx-sweetalert2";
import { MetaService } from "ng2-meta";
declare var google: any;

@Component({
  selector: "company-detail",
  styleUrls: ["./company-detail.component.css"],
  templateUrl: "./company-detail.component.html"
})
export class CompanyDetailComponent implements OnInit {
  company: Company;
  reviewModel: CompanyServiceReview;
  companyId: number;
  ratings: any = RatingTypeEnum;
  cities: City[] = [];
  companyRating: number = 0;
  companyCities: City[] = [];
  companyServices: CompanyService[] = [];
  companyServiceReviews: CompanyServiceReview[] = [];
  URL: string = config.URL;

  constructor(
    // private metaService: MetaService, 
    private route: ActivatedRoute,
    private ApiService: ApiService,
    // private _swal2: SweetAlertService
  ) {
    this.companyId = this.route.snapshot.params.companyId;
    this.company = new Company();
    this.reviewModel = new CompanyServiceReview();
  }

  ngOnInit() {
    setTimeout(() => {
      // this.metaService.setTitle('Professional Packers and Movers in ' + this.companyId);
    }); //example

    this.ApiService.get("Cities", "").subscribe(
      cities => (this.cities = cities)
    );

    this.getCompanyServiceReview();

    this.ApiService.get("company/" + this.companyId, "").subscribe(
      Company => {
        this.company = Company;
        this.companyCities = this.uniqueBy(
          this.company.companyAddresses.map(
            companyAddress => companyAddress.city
          ),
          "name"
        );
        this.companyServices = this.uniqueBy(
          this.company.companyServices.map(
            companyService => companyService.service
          ),
          "id"
        );
      }
    );

    var mapCanvas2 = document.getElementById("companyMap");
    var mapOptions2 = {
      center: new google.maps.LatLng(51.5, -0.2),
      zoom: 10
    };
    var map = new google.maps.Map(mapCanvas2, mapOptions2);
  }

  getCompanyServiceReview() {
    this.ApiService.get(
      "CompanyServiceReviews/?companyId=&companyName=" + this.companyId + "&pageSize=10000",
      ""
    ).subscribe(reviews => {
      this.companyServiceReviews = (reviews || []).filter(a => a.isActive);
      let count: number = this.average(this.companyServiceReviews, "rating") || 0;
      this.companyRating = parseFloat(count.toFixed(1)); //count >= 10 ? 10 : (count * 10);
    });
  }

  goToReview() {
    var pageElement = document.getElementById("writeareviewpanel");
    if (pageElement) {
      this.scrollToElement(pageElement);
    }
  }

  private scrollToElement(pageElement) {
    var positionX = 0,
      positionY = 0;

    while (pageElement != null) {
      positionX += pageElement.offsetLeft;
      positionY += pageElement.offsetTop;
      pageElement = pageElement.offsetParent;
      window.scrollTo(positionX, positionY);
    }
  }

  submitReview(form) {
    this.reviewModel.companyId = this.company.id;
    this.reviewModel.ratingType = this.ratings[this.reviewModel.ratingType];
    this.reviewModel.companyServiceId = this.company.companyServices[0].id;
    if (form.valid) {
      this.ApiService.post("CompanyServiceReviews", this.reviewModel).subscribe(
        review => {
          // this._swal2
          //   .success({ title: "Thank you for submitting your feedback." })
          //   .then(() => {
          //     this.reviewModel = new CompanyServiceReview();
          //     this.getCompanyServiceReview();
          //     form.markAsPristine();
          //     form.markAsUntouched();
          //   });
        }
      );
    }
  }

  average(array, prop) {
    if (prop) {
      return (
        (array || []).reduce(function(a, b) {
          return a + b[prop];
        }, 0) / (array || []).length
      );
    } else {
      return (
        (array || []).reduce(function(a, b) {
          return a + b;
        }) / (array || []).length
      );
    }
  }

  uniqueBy(array, field) {
    const flags = new Set();
    return (array || []).filter(entry => {
      if (flags.has(entry[field])) {
        return false;
      }
      flags.add(entry[field]);
      return true;
    });
  }
}
