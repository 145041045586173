import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Company } from '../modals/'

@Injectable()
export class CompanyService {
  constructor(private ApiService: ApiService) { }

  get(id: number) {
    return this.ApiService.get(`Companies/${id}`, '');
  }

  getAll() {
    return this.ApiService.get('Companies', '');
  }

  put(company: Company) {
    return this.ApiService.put('Companies', company);
  }

  post(company: Company) {
    return this.ApiService.post('Companies', company);
  }
}
